import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '@/store'

Vue.use(Router)

let router = new Router({
    mode:'history',
    routes:[
        {
            path: "/Login",
            name: "Login",
            component: () =>
                import ('./components/Auth/loginForm'),
            meta: { requiresAuth: false }

        },
        {
            path: '/',
            redirect: '/dashBoard',
            component: () =>
                import ('@/layouts/LayOut'),
            meta: { requiresAuth: true },
            children: [
                {
                    name: 'Dashboard',
                    path: 'dashBoard',
                    component: () => import ('./components/Dashboard/dashBoard'),
                },
                {
                    path: "Category",
                    name: "CategoryList",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Category/mainCategory"),
                },
                {
                    path: "ServiceCategory",
                    name: "serviceCategory",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Category/serviceCategory"),
                },
                {
                    path: "Sellers",
                    name: "SellerList",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Seller/sellerList"),
                },
                {
                    path: "SellerInfo",
                    name: "SellerInfo",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Seller/sellerInfo"),
                },
                {
                    path: "CustomerList",
                    name: "CustomerList",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Users/Customer/customerList"),
                },
                {
                    path: "CustomerInfo",
                    name: "CustomerInfo",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Users/Customer/customerInfo"),
                },
                {
                    path: "Banner",
                    name: "Banner",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Banner/bannerInfo"),
                },
                {
                    path: "QRCodes",
                    name: "QRCodes",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Seller/sellerQRList"),
                },
                {
                    path: "ActivityLog",
                    name: "ActivityLog",
                    meta: {
                      requiresAuth: true,
                    },
                    component: () => import("./components/Seller/sellerActivityList.vue"),
                },

            ]
        }

    ]
})

router.beforeEach((to, from, next) => {
    if (localStorage.getItem('token')) {
        var newURL = ''
        newURL = "/validate/admin";
        axios({
                method: "GET",
                url: newURL,
                headers: {
                    "token": localStorage.getItem("token")
                }
            })
            .then(response => {
                if (!response.data.status) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("role");
                    store.commit("logoutUser");
                    store.commit("sessionOut", true)
                    next({
                        name: 'Login'
                    })
                    return
                }
            })
    }

    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        next({
            name: 'Login'
        })
        return
    }
    if (to.path === '/Login' && store.state.isLoggedIn) {
        next({
            path: '/'
        })
        return
    }
    next()
})

export default router